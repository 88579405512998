/* 전체 화면을 덮는 모달 스타일 */
.album-modal {
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 1); /* 어두운 배경 */
  z-index: 2000;
}

/* 오른쪽 상단 고정된 헤더 스타일 */
.header-container {
  position: absolute;
  top: 40px; /* 상단 여백 */
  right: 120px; /* 닫기 버튼과의 거리 충분히 확보 */
  text-align: right;
  z-index: 1000; /* 닫기 버튼보다 위 */
  color: white;
}

/* 닫기 버튼 스타일 */
.close-btn {
  position: absolute;
  top: 40px;
  right: 20px;
  width: 40px; /* 버튼 크기 */
  height: 40px;
  background: rgba(255, 255, 255, 0.2);
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center; /* 세로 중앙 정렬 */
  justify-content: center; /* 가로 중앙 정렬 */
  transition: background 0.2s ease-in-out;
}

.close-btn:hover {
  background: rgba(255, 255, 255, 0.4);
}

.close-btn:hover {
  background: rgba(255, 255, 255, 0.4);
}

.close-btn:hover {
  background: rgba(255, 255, 255, 0.8);
}

/* 모달 내부 컨텐츠 스타일 (배경 투명) */
.modal-content {
  background: transparent; /* 완전 투명 */
  width: 40%;
  color: white; /* 텍스트 가독성을 위해 흰색 */
  border-radius: 40px;
  padding: 20px;
  text-align: center;
  z-index: 2000;
}

.modal-content-region {
  margin-top: 50px;
}

.modal-content-regionName {
  font-size: 4rem;
  margin-bottom: 20px;
}

.modal-content-region-OperationRatio {
  font-size: 2rem;
  margin-bottom: 20px;
}

.modal-content-equip-title {
  font-size: 3rem;
  margin-bottom: 40px;
}

.modal-content-equip-status {
  font-size: 1.6rem;
}

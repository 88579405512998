@charset "utf-8";

@font-face {
  font-family: "nanumgothic-extrabold";
  src: url("../static/NanumGothicExtraBold.ttf") format("ttf");
}

@font-face {
  font-family: "nanumgothic-bold";
  src: url("../static/NanumGothicBold.ttf") format("ttf");
}

@font-face {
  font-family: "nanumgothic";
  src: url("../static/NanumGothic.ttf") format("ttf");
}

.sidebar {
  width: 120px !important;
  /* width: 6vw !important; */
}

.ui.sidebar.menu > a {
  position: relative;
  align-items: center;
  text-align: center;
  height: 82px !important;
  top: 101.52px;
  display: flex !important;
  justify-content: center !important;
}

.pushable {
  overflow-x: auto !important;
}

/* .pusher { */
/* flex-direction: column; */
/* flex-wrap: wrap; */
/* justify-content: space-between; */
/* gap: 20px; */
/* background-color: #20242b; */
/* color: white; */
/* height: 100vh; */
/* position: relative; */
/* } */

.pusher {
  height: 100vh;
  width: 100vw;
  overflow: scroll !important;
  overflow-x: hidden !important;
  scrollbar-track-color: red;
}

.ui.segment.main-header-container {
  display: inline-block;
  position: absolute;
  top: 27px;
  left: 149px;
}

.ui.segment.main-header-container-2 {
  margin: 0;
  padding: 0;
}

.ui.segment.login-header-container {
  display: inline-block;
  position: absolute;
  top: 36%;
  left: 45%;
}

.login_form {
  width: 100%;
  height: 50px;
  position: relative;
}

.login_div {
  margin-top: 8%;
  display: flex;
  justify-content: space-evenly;
}

.login_div > button {
  color: black;
  border: 2px solid #00cc66;
  border-radius: 5px;
}

.login_div > button:hover {
  cursor: pointer;
}

.login_input {
  border: 2px solid #00cc66;
  border-radius: 5px;
  width: 250px;
  height: 30px;
}

.ui.header.main-header {
  color: #00cc66;
  font-size: 55px;
  margin: 0;
  height: fit-content;
  line-height: 1.1em;
  top: 27px;
  font-weight: 800;
  font-family: "nanumgothic-extrabold";
}

.ui.header.main-header-2 {
  color: white;
  display: inline-block;
  vertical-align: top;
  font-size: 35px;
  margin: 0;
  padding-left: 9px;
  line-height: 1em;
}

.ui.header.temp_btn {
  color: white;
  display: inline-block;
  vertical-align: bottom;
  font-size: 20px;
  margin: 0;
  padding-left: 9px;
  color: white;
  padding-left: 20;
}

.ui.header.time {
  position: absolute;
  top: 50px;
  left: 1436px;
  width: 321px;
  color: white;
  font-size: 18px;
  margin: 0;
  font-weight: 600;
  font-family: "nanumgothic-bold";
}

.ui.header.main-header-2 > .subtitle {
  color: white;
  font-weight: 100;
  font-size: 20px;
  font-weight: 800;
  font-family: "nanumgothic-extrabold";
}

.ui.header.main-header-2 > .subtitle-2 {
  color: white;
  font-weight: 100;
  font-size: 20px;
  float: right;
}

div.column.as-history-title {
  text-align: center;
  padding: 12px;
  font-size: 20px;
  background-color: #20242b;
  border-radius: 12px 12px 0 0;
  font-weight: 600;
  font-family: "nanumgothic-bold";
}

div > div.twelve.column.row.padding-zero > div.column.as-history-title {
  width: 150px !important;
}

div > div.twelve.column.row.as-padding-zero > div.column.as-history-title {
  width: 150px !important;
}

div.column.as-history-title::after {
  content: "";
  position: absolute;
  top: 28px;
  right: -20px;
  width: 24px;
  height: 24px;
  background: #20242b;
  mask-image: radial-gradient(
    circle 14px at 24px 0,
    transparent 0,
    transparent 20px,
    black 12px
  );
}

.ui.input > input {
  background: #bdbec0 !important;
  width: 664px;
  height: 40px;
}

.ui.input > input::placeholder {
  color: #4f5359 !important;
  font-size: 18px !important;
}

.ui.segment.container-1 {
  position: absolute;
  left: 147px;
  top: 113px;
  width: 589px;
}

.ui.grid.content-head {
  overflow: hidden;
  border-radius: 8px;
}

.row.content-1-title-container {
  background-color: #19181d;
  padding: 0 !important;
  font-weight: 800;
  font-family: "nanumgothic-extrabold";
}

.column.content-1-title {
  color: white;
  padding-top: 15px;
  padding-left: 31.5px !important;
  font-size: 20px;
}

.row.content-1-content-container {
  background-color: #19181d;
  justify-content: space-around;
  padding: 0 !important;
}

.ui.grid.content-1-content {
  background-color: #232428;
  padding: 10px;
  margin: 15px;
  height: 357px;
}

span.subtitle {
  font-size: 18px;
  color: white;
  font-weight: 600;
  font-family: "nanumgothic-bold";
}

.row.gap {
  height: 70px;
}

svg.image {
  margin-top: 14px;
  margin-bottom: 14px;
}

span.number {
  font-size: 23px;
  font-weight: 500;
  color: #f9cb0b;
  font-weight: 800;
  font-family: "nanumgothic-extrabold";
}

span.unit {
  font-size: 20px;
  font-weight: 500;
  color: white;
  font-weight: 800;
  font-family: "nanumgothic-extrabold";
}

.row.rectangle-1 {
  width: 121px;
  height: 121px;
  font-size: 18px;
  font-weight: 500;
  color: white;
  line-height: 1.5;
  /* border: 3px solid white; */
  border-radius: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: 800;
  font-family: "nanumgothic-extrabold";
  position: relative;
  overflow: hidden;
}

.row.circle-1 {
  width: 101px;
  height: 101px;
  font-size: 18px;
  font-weight: 500;
  color: white;
  line-height: 1.5;
  border: 4px solid white;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: 800;
  font-family: "nanumgothic-extrabold";
}

.row.circle-2 {
  width: 101px;
  height: 101px;
  font-size: 18px;
  font-weight: 500;
  color: #b2fe05;
  line-height: 1.5;
  border: 4px solid #b2fe05;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: 800;
  font-family: "nanumgothic-extrabold";
}

.row.circle-3 {
  width: 101px;
  height: 101px;
  font-size: 18px;
  font-weight: 500;
  color: #ff2400;
  line-height: 1.5;
  border: 4px solid #ff2400;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: 800;
  font-family: "nanumgothic-extrabold";
}

.row.circle-4 {
  width: 101px;
  height: 101px;
  font-size: 18px;
  font-weight: 500;
  color: #9c9c9c;
  line-height: 1.5;
  border: 4px solid #9c9c9c;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: 800;
  font-family: "nanumgothic-extrabold";
}

.ui.segment.container-2 {
  position: absolute;
  left: 746px;
  top: 113px;
  width: 269px;
}

.row.content-2-title-container {
  background-color: #1a191e;
  padding: 1px !important;
  border-radius: 12px 12px 0px 0px;
}

.content-2-search-container {
  background-color: #1a191e;
  padding: 1px !important;
}

.column.content-2-title {
  color: white;
  padding: 13px !important;
  padding-left: 22px !important;
  font-size: 20px;
  font-weight: 800;
  font-family: "nanumgothic-extrabold";
}

.row.content-2-content-container {
  background-color: #1a191e;
  padding: 20px 0 10px 0;
  height: 374px;
}

.row.content-2 {
  display: flex;
  margin-left: -9px;
  justify-content: space-between;
  display: flex;
  padding: 10.5px;
  font-size: 18px;
  font-weight: 800;
  font-family: "nanumgothic-extrabold";
}

.ui.segment.container-3 {
  background-color: #1a191e;
  position: absolute;
  overflow: hidden;
  left: 1025px;
  top: 113px;
  border: 0;
  width: 869px;
  overflow: hidden;
  border-radius: 8px;
}

.row.chart-container {
  background-color: #232428;
  margin: 0px 16px 17px 16px;
  height: 357px;
}

.column.content-4-title {
  color: white;
  padding: 14px !important;
  padding-left: 26.5px !important;
  font-size: 20px;
  font-weight: 800;
  font-family: "nanumgothic-extrabold";
  text-align: center;
}

table.main-event-table {
  min-height: 400px;
  padding: 20px;
  margin: 0px 16px 30px 18px !important;
  background-color: #20242b !important;
  table-layout: fixed;
  width: 100%;
}

table.event-table {
  margin: -15px 16px 0 18px !important;
  background-color: #20242b !important;
  /* table-layout: fixed; */
  width: 100%;
}

table.table-2 {
  padding: 0 2px 60px 2px !important;
  margin: -15px 16px -70px 16px !important;
  background-color: #20242b !important;
  table-layout: fixed;
  width: 100%;
}

.energy-table-2 {
  position: relative;
  padding: 0 2px 60px 2px !important;
  margin: -15px 16px -19px 16px !important;
  background-color: #20242b !important;
  table-layout: fixed;
  width: 100%;
  min-height: 320px;
}

thead {
  font-size: 18.5px;
  line-height: 0.65em;
  font-weight: 600 !important;
  font-family: "nanumgothic-bold";
}

table > thead {
  height: 46px;
}

tbody > tr > td {
  line-height: 0.915em;
  font-size: 18.5px;
}

thead.table-header {
  background-color: #35353a !important;
}

.gray-color {
  background-color: #20242b !important;
}

tfoot > tr > th > div > a {
  font-size: 23px;
  font-weight: 800;
  font-family: "nanumgothic-extrabold";
  padding: 6.2px !important;
  min-width: 0em !important;
}

tfoot > tr > th > div > a:nth-child(3),
tfoot > tr > th > div > a:nth-child(4) {
  color: #6f6e71 !important;
}

.white-color {
  margin-left: -10px;
  color: white !important;
}

.green-color {
  color: #00cc66;
}

.ui.segment.basic-segment {
  position: absolute;
  top: 163px;
  left: 190px;
  width: 1662px;
  height: 900px;
  color: white;
}

.ui.segment.fac-basic-segment {
  position: absolute;
  top: 150px;
  left: 190px;
  width: 1662px;
  height: 900px;
  color: white;
}

.padding-zero {
  padding: 0 !important;
}

.column.content-section {
  padding: 14px;
  background-color: #20242b;
  border-radius: 0px 12px 0px 0px;
}

div.input {
  width: 664px;
  height: 40px;
  border-radius: 3px;
  margin-left: 12px !important;
}

button.btn-green {
  margin-left: 10px !important;
  width: 111px;
  height: 40px;
  background: #00cc66 0% 0% no-repeat padding-box !important;
  color: white !important;
  border-radius: 3px;
  font-weight: 600;
  font-family: "nanumgothic-bold";
}

.btn-event {
  margin-left: 10px !important;
  width: 111px;
  height: 40px;
  background: #00cc66 0% 0% no-repeat padding-box !important;
  color: white !important;
  border-radius: 3px;
  font-weight: 600;
  font-family: "nanumgothic-bold";
}

button.fac-main-btn-green {
  margin-left: 10px !important;
  width: 111px;
  height: 40px;
  background: #00cc66 0% 0% no-repeat padding-box !important;
  color: white !important;
  border-radius: 3px;
  font-weight: 600;
  font-family: "nanumgothic-bold";
}

button.fac-btn-green {
  margin-left: 7px !important;
  width: 111px;
  height: 40px;
  font-weight: 00;
  font-family: "nanumgothic-bold";
}

.row.table-container {
  background-color: #20242b;
  overflow: hidden;
  border-radius: 0px 0px 12px 12px;
}

.row.fac-table-container {
  min-height: 380px;
  background-color: #20242b;
  overflow: hidden;
  border-radius: 0px 0px 12px 12px;
}

/* .event-table-container--expanded {
height: 680px;
background-color: #20242b;
overflow: hidden;
border-radius: 0px 0px 12px 12px;
} */

.event-table-container {
  min-height: 390px;
  background-color: #20242b;
  overflow: hidden;
  border-radius: 0px 0px 12px 12px;
}

.event-address-table-container--expanded {
  height: 680px;
  background-color: #20242b;
  overflow: hidden;
  border-radius: 0px 0px 12px 12px;
}

.event-address-table-container {
  height: 300px;
  background-color: #20242b;
  overflow: hidden;
  border-radius: 0px 0px 12px 12px;
}

.row.as-table-container {
  min-height: 390px;
  background-color: #20242b;
  overflow: hidden;
  border-radius: 0px 0px 12px 12px;
}

/* .row.as-table-container--expanded {
height: 350px;
background-color: #5f7db0;
background-color: #20242b;
overflow: scroll;
border-radius: 0px 0px 12px 12px;
} */

.row.energy-table-container {
  height: 340px;
  background-color: #20242b;
  overflow-y: scroll;
  border-radius: 0px 0px 12px 12px;
}

.row.energy-table-container--expanded {
  min-height: 380px;
  background-color: #20242b;
  overflow: hidden;
  border-radius: 0px 0px 12px 12px;
}

.table-header-color {
  background-color: #35353a;
}

.ui.segment.basic.container-4 {
  position: absolute;
  top: 630px;
  left: 190px;
  width: 1662px;
  color: white;
}

.ui.segment.content-4-container {
  position: absolute;
  left: 147px;
  top: 550px;
  width: 868px;
}

.column.content-4-header {
  text-align: left;
  padding: 14px !important;
  padding-bottom: 0 !important;
  font-size: 20px !important;
  background-color: #20242b;
  border-radius: 12px 12px 0px 0px;
}

button.btn-green-2 {
  margin-left: 20px !important;
  width: 182px;
  height: 40px;
  background: #00cc66 0% 0% no-repeat padding-box !important;
  color: white !important;
  border-radius: 3px;
  font-weight: 600;
  font-family: "nanumgothic-bold";
}

div.font-size-18 {
  font-size: 18px;
  font-weight: 800;
  font-family: "nanumgothic-extrabold";
}

div.font-size-18-with-margin {
  margin-top: 18px;
  font-size: 18px;
  font-weight: 800;
  font-family: "nanumgothic-extrabold";
}

div.font-size-18-with-margin-10 {
  margin-top: 10px;
  font-size: 18px;
  font-weight: 800;
  font-family: "nanumgothic-extrabold";
}

.textfield {
  margin-top: 6.5px;
  padding: 17px;
  width: 251px;
  height: 59px;
  /* UI Properties */
  background: #75787b 0% 0% no-repeat padding-box;
  text-align: end;
}

.number {
  color: #f7931e;
  font-size: 28px;
}

.unit {
  color: white;
  font-size: 28px;
}

.placeholder-image {
  height: 444px;
  margin-left: 70px;
}

.row.content-4-sub-content-container {
  width: 602px !important;
  padding: 25px 33px !important;
  margin-right: 51px;
  background-color: #393d42;
  border-radius: 12px;
}

.row.content-4-sub-content-container-2 {
  width: 318px !important;
  padding: 25px 33px !important;
  margin-right: 51px;
  background-color: #393d42;
  border-radius: 12px;
}

.row.content-4-sub-content-container-3 {
  width: 580px !important;
  padding: 9px 22px !important;
  margin-right: 51px;
  background-color: #393d42;
  border-radius: 12px;
}

.row.content-4-container {
  justify-content: space-between !important;
  background-color: #20242b;
  padding: 0 !important;
  padding-bottom: 14px !important;
  overflow: hidden;
  border-radius: 0px 0px 12px 12px;
}
.row.energy-container {
  justify-content: space-between !important;
  background-color: #20242b;
  padding: 0 !important;
  padding-bottom: 14px !important;
  overflow: hidden;
  border-radius: 0px 0px 12px 12px;
}

.facSelector {
  position: relative;
  margin-top: 7% !important;
  left: 46.3%;
}

.energy-button {
  background-color: transparent;
  border: 0;
  color: white;
}
.energy-button:hover {
  cursor: pointer;
}

.as-button {
  background-color: transparent;
  border: 0;
  color: #00cc66;
}

.red-text {
  color: red;
}

.as-button:hover {
  cursor: pointer;
}

.ui.table.footer {
  display: flex;
}

.main-pagination {
  position: absolute;
  margin-top: 1%;
  /* top: 117.6%; */
  right: 0;
}

.fac-pagination {
  /* position: absolute;
top: 89.2%;
right: 3%; */
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin: 1% 0 0 0.5%;
}

.as-pagination {
  position: absolute;
  margin-top: 1%;
  right: 0;
}

.as-pagination--expanded {
  /* position: absolute;
top: 58.6%;
right: 2.6%; */
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin: 1% 0 0 0.5%;
}

.energy-pagination {
  position: absolute;
  /* top: 85.7%; */
  right: -1%;
  bottom: 2%;
}

.energy-pagination--expanded {
  position: absolute;
  /* top: 92%; */
  bottom: 1%;
  right: -1%;
}

.loading-background {
  position: absolute;
  width: 90%;
  height: 15%;
  top: 43%;
  padding: 3%;
  left: 4%;
  background: #20242b;
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.e-loading {
  position: absolute;
  width: 90%;
  height: 55%;
  top: 43%;
  padding: 3%;
  left: 4%;
  background: #20242b;
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loading {
  font: 1rem "Noto Sans KR";
  color: white;
  text-align: center;
  font-size: 20px;
}

.mainEmpty-background {
  position: absolute;
  right: 1.5%;
  width: 1700px;
  height: 300px;
  /* top: 12.5%; */
  font-size: 25px;
  background: #20242b;
  z-index: 998;
  display: flex;
  border-radius: 0 0 12px 12px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.eventEmpty-background {
  position: absolute;
  width: 100%;
  height: 250px;
  font-size: 25px;
  background: #20242b;
  z-index: 998;
  display: flex;
  border-radius: 0 0 12px 12px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.facEmpty-background {
  position: absolute;
  width: 100%;
  height: 650px;
  top: 12.5%;
  font-size: 25px;
  background: #20242b;
  z-index: 998;
  display: flex;
  border-radius: 0 0 12px 12px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.asEmpty-background {
  position: absolute;
  font-size: 25px;
  width: 100%;
  height: 250px;
  background: #20242b;
  z-index: 998;
  display: flex;
  border-radius: 0 0 12px 12px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.as-container-4 {
  position: absolute;
  top: 90%;
  left: 175px;
  width: 1662px;
  color: white;
}

.issue-container-4 {
  position: absolute;
  top: 100%;
  left: 175px;
  width: 1662px;
  color: white;
}

.event-content-4-container {
  position: absolute;
  left: 147px;
  top: 15%;
  width: 1747px;
}

.event-pagination {
  position: absolute;
  margin-top: 1%;
  right: 0;
}

.event-address-pagination--expanded {
  position: absolute;
  margin-top: 10px;
  top: 88.2%;
  right: 3%;
}

.event-address-pagination {
  position: absolute;
  margin-top: 10px;
  top: 53.15%;
  right: 3%;
}

.event-title {
  color: white;
  padding: 30px !important;
  font-size: 30px;
  font-weight: 700;
  font-family: "nanumgothic-extrabold";
  text-align: center;
}

#eventRefresh {
  width: "10px";
  height: "10px";
}

.main-eventButton {
  background-color: #1a191e;
  color: white;
  border: none;
  cursor: pointer;
}

.eventButton {
  background-color: #20242b;
  color: white;
  border: none;
  cursor: pointer;
}

.issueDetail-input {
  cursor: pointer;
}

.ui.transparent.input input[type="text"] {
  border-bottom: 1px solid white !important;
  color: white;
  opacity: 0.8;
  font-size: "19.5px";
}

.ui.transparent.input input[type="text"]::placeholder {
  color: white !important;
  opacity: 0.8 !important;
  font-weight: 300 !important;
}

@keyframes fadeout {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.event-search {
  display: absolute;
  top: 20%;
}

.loginInput {
  border-bottom: 1px solid white !important;
  color: white;
  opacity: 0.8;
  font-size: "19.5px";
}

.loginInput::placeholder {
  color: white !important;
  opacity: 0.8 !important;
  font-weight: 300 !important;
}

.ui.segment.basic-segment2 {
  position: absolute;
  top: 621px;
  left: 190px;
  width: 1662px;
  height: 520px;
  color: white;
}

.ui.segment.as-segment2 {
  position: absolute;
  top: 621px;
  left: 190px;
  width: 1662px;
  height: 520px;
  color: white;
}

.ui.segment.basic-segment2-break {
  position: absolute;
  top: 621px;
  left: 190px;
  width: 1662px;
  height: 520px;
  color: white;
}

.ui.segment.break {
  position: absolute;
  top: 721px;
  left: 190px;
  width: 1662px;
  height: 520px;
  color: white;
}

.as-break {
  position: absolute;
  top: 21px;
  left: 20px;
  width: 1662px;
  height: 520px;
  color: white;
  font-size: 28px;
}

.row.content-section2 {
  padding: 22px 26px;
  background-color: #20242b;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
}

.row.content-section2.checkBroken {
  padding: 27px 62px;
  flex-direction: column;
}

.break {
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  color: #fff;
  font-size: 25px;
  font-family: nanumgothic;
  font-weight: 700;
}

.row.content-section2.checkBroken > div:nth-child(2) {
  border-bottom: 1px solid #373a3f;
  margin-top: 25px;
  margin-bottom: 20px;
}

.column.content-section-inside {
  padding: 16px 26px;
  background-color: #393d42;
  border-radius: 10px;
  width: 477px;
  height: 477px;
}

.content-section-inside2 {
  padding: 30px 12px;
  background-color: #393d42;
  border-radius: 10px;
  width: 477px;
  height: 380px;
}

.column.content-section-inside2.margin {
  margin-left: 26px;
}

.column.content-section-inside.withcustompadding {
  padding: 32px 45px;
  margin-left: 26px;
  background-color: #393d42;
  border-radius: 10px;
  width: 1105px;
  height: 477px;
}

.column.third,
.column.fourth {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
}

.as.is.rejected {
  display: table-cell;
  vertical-align: middle;
  font-family: Noto Sans;
  text-align: center;
  padding: 7px 5px;
  width: 46px;
  font-size: 12px;
  height: 26px;
  color: white;
  font-weight: 700;
  border-radius: 22px;
  background-color: #fc4e47;
}

.as.is.processing {
  display: table-cell;
  vertical-align: middle;
  font-family: Noto Sans;
  text-align: center;
  padding: 7px 5px;
  width: 77px;
  font-size: 12px;
  height: 26px;
  color: white;
  font-weight: 700;
  border-radius: 22px;
  background-color: #f6aa08;
}

.as.progress.contentbox.isrejected {
  align-items: flex-start;
  background: #fc4e47;
}

.as.title {
  color: #fff;
  font-size: 18px;
  font-family: nanumgothic;
  font-weight: 600;
  margin-top: 12px;
}

.as.sub.title1 {
  color: #fff;
  font-size: 14px;
  font-family: appler;
  margin-top: 8px;
}

.as.sub.title2 {
  color: #fff;
  font-size: 14px;
  font-family: appler;
  margin-top: 4px;
}

.as.divider {
  opacity: 0.1;
  border-bottom: 1px solid #ffffff;
  margin-top: 13px;
}

.as.progress.title {
  color: #fff;
  font-size: 20px;
  font-family: nanumgothic;
  font-weight: 700;
  margin-top: 19px;
}

.as.progress.wrapper {
  background: transparent;
  padding: 15px;
  max-height: 260px;
  overflow: scroll;
  background-color: #393d42;
  box-shadow: 5px 10px 0 0 #393d42;
  mask-image: linear-gradient(
    to bottom,
    #393d42 calc(100% - 30px),
    transparent 100%
  );
}
.as.progress.wrapper::-webkit-scrollbar {
  display: none;
}

.as.progress.contentbox {
  width: 354px;
  height: 68px;
  background: #75777b;
  border-radius: 12px;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.as.progress.contentbox.aligntop {
  align-items: flex-start;
}

.as.progress.contentbox.isprogressing {
  align-items: flex-start;
  background: #0dbf6b;
}

.as.progress.contentbox.isrejected {
  align-items: flex-start;
  background: #fc4e47;
}

.as.progress.contentbox.empty {
  background: transparent;
  border: 1px solid white;
}

.as.progress.contentbox span:nth-child(1) {
  color: white;
  font-size: 16px;
  font-family: appler;
}

.as.progress.contentbox span:nth-child(2) {
  color: white;
  font-size: 12px;
  font-family: appler;
}

.column.content-section-inside.second {
  padding: 26px 16px;
  margin-left: 26px;
  background-color: #393d42;
  border-radius: 10px;
  width: 1105px;
  height: 477px;
}

.column.third > div:nth-child(1) {
  color: #fff;
  font-size: 20px;
  font-family: nanumgothic;
  font-weight: 700;
}

.column.third > div:nth-child(2) {
  margin-top: 28px;
  color: #fff;
  font-size: 18px;
  font-family: appler;
  margin-bottom: 29px;
}

.column.third > div:nth-child(3) {
  opacity: 0.1;
  border-bottom: 1px solid #ffffff;
  width: 480px;
}

.column.third > div:nth-child(4) {
  margin-top: 19px;
  width: 480px;
  font-size: 20px;
  font-family: nanumgothic;
  font-weight: 700;
}

.file {
  font-size: 20px;
  font-family: nanumgothic;
  font-weight: 700;
}

.fileContent {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  width: 480px;
  font-size: 20px;
  font-family: nanumgothic;
  font-weight: 700;
}

.as-fileContent {
  display: flex;
  flex-direction: row;
  margin-top: 31px;
  width: 480px;
  height: 30px;
  font-size: 20px;
  font-family: nanumgothic;
  font-weight: 700;
  justify-content: space-between;
  align-items: center;
}

.as-fileContent-1 {
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  width: 480px;
  font-size: 20px;
  font-family: nanumgothic;
  font-weight: 700;
  justify-content: space-between;
  align-items: center;
}

.as-fileContent-3 {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  width: 480px;
  font-size: 20px;
  font-family: nanumgothic;
  font-weight: 700;
  justify-content: space-between;
  align-items: center;
}

.column.third > div:nth-child(5),
.column.third > div:nth-child(6),
.column.third > div:nth-child(7),
.column.third > div:nth-child(8) {
  display: flex;
  flex-direction: row;
  margin-top: 40px;
  width: 480px;
  font-size: 20px;
  font-family: nanumgothic;
  font-weight: 700;
}

.column.third > div:nth-child(5).aligned,
.column.third > div:nth-child(6).aligned,
.column.third > div:nth-child(7).aligned,
.column.third > div:nth-child(8).aligned {
  justify-content: space-between;
}

.column.third > div:nth-child(5) > div:nth-child(2),
.column.third > div:nth-child(6) > div:nth-child(2),
.column.third > div:nth-child(7) > div:nth-child(2),
.column.third > div:nth-child(8) > div:nth-child(2) {
  padding-bottom: 16px;
  border-bottom: 1px solid #4e5054;
}

/* .column.third > div:nth-child(5) > div:nth-child(2)[contenteditable="true"].empty,
.column.third > div:nth-child(6) > div:nth-child(2)[contenteditable="true"].empty,
.column.third > div:nth-child(7) > div:nth-child(2)[contenteditable="true"].empty,
.column.third > div:nth-child(8) > div:nth-child(2)[contenteditable="true"].empty {
color: #AAA;
font-size: 18px;
font-family: appler;
font-weight: 400;
line-height: normal;
} */

.column.third
  > div:nth-child(5)
  > div:nth-child(2)[contenteditable="true"]:empty:before,
.column.third
  > div:nth-child(6)
  > div:nth-child(2)[contenteditable="true"]:empty:before,
.column.third
  > div:nth-child(7)
  > div:nth-child(2)[contenteditable="true"]:empty:before,
.column.third
  > div:nth-child(8)
  > div:nth-child(2)[contenteditable="true"]:empty:before {
  content: attr(placeholder);
}

.phonenum[contenteditable="true"]:empty:before {
  content: attr(placeholder);
}

.popup.popup-2 > div:nth-child(4) > div[contenteditable="true"]:empty:before,
.popup.popup-2 > div:nth-child(5) > div[contenteditable="true"]:empty:before {
  content: attr(placeholder);
}

.column.fourth > div:nth-child(3) > button:first-child.addmodebtn {
  background-color: #0dbf6b;
  border: none;
}

.as.progress.wrapper::-webkit-scrollbar {
  display: none;
}

div.column.as-history-title::after {
  content: "";
  position: absolute;
  top: 28px;
  right: -20px;
  width: 24px;
  height: 24px;
  background: #20242b;
  mask-image: radial-gradient(
    circle 14px at 24px 0,
    transparent 0,
    transparent 20px,
    black 12px
  );
}

.column.fourth > div:nth-child(3) {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 40px;
  margin-top: 25px;
}

.column.fourth > div:nth-child(3) > button {
  width: 128px;
  height: 40px;
}

.write-button {
  display: flex;
  margin-top: 20px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid white;
  background-color: transparent;
  color: #fff;
  font-size: 18px;
  font-family: nanumgothic;
  font-weight: 700;
  border-radius: 2px;
}

.update-button {
  display: flex;
  margin-top: 30px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid white;
  background-color: transparent;
  color: #fff;
  font-size: 18px;
  font-family: nanumgothic;
  font-weight: 700;
  border-radius: 2px;
}

.notImg-write-button {
  display: flex;
  margin-top: 95px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid white;
  background-color: transparent;
  color: #fff;
  font-size: 18px;
  font-family: nanumgothic;
  font-weight: 700;
  border-radius: 2px;
}

.notImg-update-button {
  display: flex;
  margin-top: 22px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid white;
  background-color: transparent;
  color: #fff;
  font-size: 18px;
  font-family: nanumgothic;
  font-weight: 700;
  border-radius: 2px;
}

.check-button-3 {
  display: relative;
  margin: 41px 0 0 430px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid white;
  background-color: transparent;
  color: #fff;
  font-size: 18px;
  font-family: nanumgothic;
  font-weight: 700;
  border-radius: 2px;
}

.check-button-1 {
  display: relative;
  margin: 46px 0 0 430px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid white;
  background-color: transparent;
  color: #fff;
  font-size: 18px;
  font-family: nanumgothic;
  font-weight: 700;
  border-radius: 2px;
}

.check-button {
  display: relative;
  margin: 62px 0 0 430px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid white;
  background-color: transparent;
  color: #fff;
  font-size: 18px;
  font-family: nanumgothic;
  font-weight: 700;
  border-radius: 2px;
}

.update-btn {
  display: flex;
  margin: 90px 0 -30px 0;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid white;
  background-color: transparent;
  color: #fff;
  font-size: 18px;
  font-family: nanumgothic;
  font-weight: 700;
  border-radius: 2px;
}
.upload-btn {
  height: 35px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid white;
  background-color: transparent;
  color: #fff;
  font-size: 18px;
  font-family: nanumgothic;
  font-weight: 700;
  border-radius: 10px;
}

.write-content {
  padding: 3px;
  margin-top: 15px;
  width: 480px;
  height: 210px;
  border: 1px solid white;
  background-color: transparent;
  color: #fff;
  font-size: 18px;
  font-family: nanumgothic;
  font-weight: 700;
  border-radius: 5px;
  outline: 0;
  overflow: auto;
  resize: none;
}

.update-content {
  padding: 3px;
  margin-top: 15px;
  width: 480px;
  height: 210px;
  border: 1px solid white;
  background-color: transparent;
  color: #fff;
  font-size: 18px;
  font-family: nanumgothic;
  font-weight: 700;
  border-radius: 5px;
  outline: 0;
  overflow: auto;
}

.update-write-content {
  padding: 20px;
  margin-top: 20px;
  width: 480px;
  height: 200px;
  border: 1px solid white;
  background-color: transparent;
  color: #fff;
  font-size: 18px;
  font-family: nanumgothic;
  font-weight: 700;
  border-radius: 5px;
  outline: 0;
  overflow: auto;
}

.deleteOnly {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid #f44522;
  background-color: #f44522;
  color: #fff;
  font-size: 18px;
  font-family: nanumgothic;
  font-weight: 700;
  border-radius: 2px;
  border: 1px solid red;
}

.column.fourth > div:nth-child(1) {
  color: #fff;
  font-size: 20px;
  font-family: nanumgothic;
  font-weight: 700;
}

.as.progress.contentbox.empty span:nth-child(1) {
  height: 25px;
}

.as.progress.contentbox.empty span:nth-child(2) {
  color: #fff;
  font-size: 18px;
  font-family: appler;
  font-style: normal;
  font-weight: 400;
}

.phonenum {
  color: #aaa;
  font-size: 16px;
  font-family: appler;
  font-weight: 400;
}

.column.fourth > div:nth-child(2).workcontent {
  background-color: transparent;
  border: 1px solid #717377;
  outline: 0;
}

.readOnly-content {
  margin-top: 15px;
  width: 480px;
  height: 230px;
  background-color: #626468;
  border-radius: 8px;
  padding: 22px 19px;
  font-size: 20px;
  outline: 0;
  overflow: auto;
}

.popup {
  position: absolute;
  box-sizing: border-box;
  border-radius: 12px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #2f3544;
  box-shadow: 7px 7px 5px rgba(0, 0, 0, 0.2);
  padding: 54px 71px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.popup-1 {
  width: 563px;
  height: 354px;
  padding: 54px 71px;
}

.popup-2 {
  width: 563px;
  height: 600px;
  padding: 62px 0px;
}

.popup-3 {
  width: 563px;
  height: 1000px;
  padding: 62px 0px;
}

.popup.popup-2 > div:nth-child(4) > div[contenteditable="true"]:empty:before,
.popup.popup-2 > div:nth-child(5) > div[contenteditable="true"]:empty:before {
  content: attr(placeholder);
}

.delete-Btn {
  padding: 14px 0 17px 0;
  width: 485px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 12px;
  background-color: #fc4e47;
  color: #fff;
  font-size: 24px;
  font-family: appler;
  font-weight: 400;
}

.column-group[contenteditable="true"].empty {
  color: #aaa;
  font-size: 18px;
  font-family: appler;
  font-weight: 400;
  line-height: normal;
}

.column-update[contenteditable="true"].empty {
  color: #aaa;
  font-size: 18px;
  font-family: appler;
  font-weight: 400;
  line-height: normal;
}

.fileBtn {
  width: 80px;
  height: 40px;
}

.pri-img {
  margin-top: -5px;
  width: 70px;
  height: 60px;
}

.main-header-container {
  width: 100%;
}

.container-1,
.container-2 {
  width: 48%;
  display: inline-block;
  vertical-align: top;
}

.container-3 {
  width: 100%;
}

.content-4-container {
  width: 48%;
  margin-top: 20px;
}
